<template>
    <div class="starting-state" v-if="!selectedArea?.properties?.name"
    >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 7.5C10.3978 7.5 10.7794 7.34196 11.0607 7.06066C11.342 6.77936 11.5 6.39782 11.5 6C11.5 5.60218 11.342 5.22064 11.0607 4.93934C10.7794 4.65804 10.3978 4.5 10 4.5C9.60218 4.5 9.22064 4.65804 8.93934 4.93934C8.65804 5.22064 8.5 5.60218 8.5 6C8.5 6.39782 8.65804 6.77936 8.93934 7.06066C9.22064 7.34196 9.60218 7.5 10 7.5ZM12 13H11V8.5H8V10.5H9V13H8V15H12V13Z" fill="#FFFFFF"/>
            </svg>
            Click on a region to see more
    </div>
    <div class="story-container" v-else>
        <div class="story-header">
            <div class="close-button" @click="closeArea">
                x
            </div>
            <div class="story-title">
                <span class="city-name">{{ getRegionName(selectedArea.properties.name) }}</span>
                <span class="province-name">Bali, Indonesia</span>
            </div>
        </div>
        <div class="scroll-container">
            <div class="see-listings" @click="goToSearch(selectedArea, 'region')">
                    SEE LISTINGS
                    </div>
        <div class="story">
            <div class="story-properties">
                <div class="spec-section">
                    <div class="spec-section-name">
                        Available properties
                    </div>
                    <div class="spec-section-container">
                        <div class="property-detail">
                            <div class="property-detail-name">
                                <img src="../assets/story-leasehold.svg" />
                                Lease Hold
                            </div>
                            <label>
                                {{ selectedArea.properties.number_of_lease_hold }}
                            </label>
                        </div>
                        <div class="property-detail">
                            <div class="property-detail-name">
                                <img src="../assets/story-freehold.svg" />
                                Free Hold
                            </div>
                            <label>
                                {{ selectedArea.properties.number_of_free_hold }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="spec-section">
                    <div class="spec-section-name">
                        Price per SQM
                    </div>
                    <div class="spec-section-container">
                        <div class="property-detail">
                            <div class="property-detail-name">
                                <img src="../assets/story-lowest.svg" />
                                Lowest
                            </div>
                            <label>
                                {{ selectedCurrency }} {{ formattedPrice(selectedArea, 'lowest_price_per_sqm') }}
                            </label>
                        </div>
                        <div class="property-detail">
                            <div class="property-detail-name">
                                <img src="../assets/story-median.svg" />
                                Median
                            </div>
                            <label>
                                {{ selectedCurrency }} {{ formattedPrice(selectedArea) }}
                            </label>
                        </div>
                        <div class="property-detail">
                            <div class="property-detail-name">
                                <img src="../assets/story-highest.svg" />
                                Highest
                            </div>
                            <label>
                                {{ selectedCurrency }} {{ formattedPrice(selectedArea, 'highest_price_per_sqm') }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="spec-section">
                    <div class="spec-section-name">
                        Learn more
                    </div>
                    <div class="spec-section-container">
                        <div class="property-detail">
                            <div class="property-detail-name">
                                <img src="../assets/story-population.svg" />
                                Population density
                            </div>
                            <label>Coming soon</label>
                        </div>
                        <div class="property-detail">
                            <div class="property-detail-name">
                                <img src="../assets/story-bnb.svg" />
                                BnB rental price
                            </div>
                            <label>Coming soon</label>
                        </div>
                    </div>
                </div>
                <div class="spec-section" v-if="selectedArea.properties.hoodmaps">
                    <div class="spec-section-name">
                        See how Hoodmaps describe this area
                    </div>
                    <div class="hoodmaps-container">
                        <iframe 
                            v-if="selectedArea.properties?.hoodmaps"
                            width="100%" 
                            height="800px" 
                            frameborder="0" 
                            :src="`https://hoodmaps.com/${selectedArea.properties.hoodmaps}-neighborhood-map`"
                            allowfullscreen>
                        </iframe>
                        <div class="hoodmaps-overlay"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default{
    name: 'MapStory',

    data() {
        return {
            markerClicked: false,
            features: [],
            observer: null,
            isHidden: false,
            activeImageIndex: 0,
            touchStartY: 0,
            touchEndY: 0,
        }
    },

    computed: {
        ...mapState({
            selectedArea: state => {
                return state.selectedArea;
            },
            selectedCurrency: state => state.selectedCurrency,
        })
    },

    methods: {

        getRegionName(region) {
            return region.split(',')[0]
        },

        closeArea() {
            this.$store.commit('setSelectedArea', {});
            this.$store.commit('setMapZoom', true);
        },

        toggleSidebar() {
            this.isHidden = !this.isHidden;
            },

        goToSearch(feature, type='region') {
      
            this.$store.dispatch('applyFilter', { filterName: 'currency', value: this.selectedCurrency });
            
            let nextPath;
            let query;

            // Go to the search page
            if (type === 'all') {

                // Construct search query
                query = { 
                    currency: this.selectedCurrency
                } 

                // Save next route to redirect after login
                nextPath = this.$router.resolve({
                    path: '/search',
                    query: query
                    }).href;

            } else {
                this.$store.dispatch('saveSearchResultInfo', { name: 'regionName', value: feature.properties.name });
                this.$store.dispatch('applyFilter', { filterName: 'region', value: feature.properties.name });

                // Construct search query
                query = { 
                    region: feature.properties.name,
                    currency: this.selectedCurrency
                }

                // Save next route to redirect after login
                nextPath = this.$router.resolve({
                    path: '/search',
                    query: query
                }).href;
            }

            // Save nextPath to local storage
            localStorage.setItem('preLoginRoute', nextPath);

            // Open search page with query
            this.$router.push({ 
                path: '/search',
                query: query
                });

            // Execute search operation and fetch data
            this.$store.dispatch('fetchData');
            
        
        },

        formattedPrice(region, priceType = 'price_per_sqm') {
            let price;
            let formatted;

            // Construct the property name dynamically based on the selected currency and priceType
            const propertyKey = `${priceType}_${this.selectedCurrency.toLowerCase()}`;

            // Get the price from the region properties
            price = region.properties[propertyKey];

            // Format the price based on the selected currency
            if (this.selectedCurrency === 'IDR' || this.selectedCurrency === 'EUR' || this.selectedCurrency === 'USD' ||
                this.selectedCurrency === 'AUD' || this.selectedCurrency === 'SGD' || this.selectedCurrency === 'GBP') {
                if (price >= 1000000) {
                    formatted = `${(price / 1000000).toFixed(2)}m`;
                } else formatted = new Intl.NumberFormat('en-US').format(price);
            } else if (this.selectedCurrency === 'BTC') {
                formatted = price;
            } else {
                return "Currency not supported";
            }

            return formatted;
        },

        previousImage(region) {
            if (this.activeImageIndex > 0) {
                this.activeImageIndex -= 1;
            } else {
                this.activeImageIndex = region.properties.images.length - 1;
            }
        },

        nextImage(region) {
            if (this.activeImageIndex < region.properties.images.length - 1) {
                this.activeImageIndex += 1;
            } else {
                this.activeImageIndex = 0;
            }
        },

        resetNavigationColors() {
            this.features.forEach((feature, index) => {
                const navigationElement = this.$refs['navigation-' + index][0];
                if (navigationElement) {
                    navigationElement.classList.remove('selected')
                }
            });
            },

    },

    mounted() {
        this.features = this.$store.state.regions;
    },

    watch: {
        'selectedArea': {
            handler(newVal) {
                this.selectedArea = newVal;
            },
            deep: true
        }
    }

}
</script>

<style scoped>
.story-container {
    position: fixed;
    top: 100px;
    right: 50px;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 30px;
    height: max-content;
    max-height: 70vh;
    width: 600px;
    overflow: hidden;
    padding: 3rem;
}

.starting-state {
    position: fixed;
    bottom: 100px;
    right: 50px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    background-color: rgba(58, 96, 131, 0.2);
    border-radius: 15px;
    width: max-content;
    padding: 1rem 2rem 1rem 2rem;
    color: #FFFFFF;
}

.scroll-container {
    width: 100%;
    overflow: auto;
}

.story-header {
    width: 100%;
    height: max-content;
    background-color: white;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #B19977;
    color: rgb(255, 255, 255);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 14px;
    line-height: 1;
}

.cover-title {
    font-size: 48px;
    color: #323232;
    font-weight: 800;
}

.cover-subtitle {
    font-size: 20px;
    font-weight: 400;
    color: #7f7e7e;
}

.story {
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.story-title {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-end;
}

.province-name {
    padding-bottom: 0.5rem;
}

.city-name {
    font-size: 38px;
    color: #323232;
    font-weight: 800;
}

.story-description {
    font-size: 16px;
    font-weight: 400;
    color: #7f7e7e;
}

.story-properties {
    display: flex;
    flex-direction: column;
    color: #7f7e7e;
    gap: 20px;
}

.property-detail {
    font-size: 14px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    gap: 1rem;
    padding-bottom: 1rem;
}

.property-detail img {
    height: 1rem;
    width: auto;
    padding-right: 0.5rem;
}

.property-detail-name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.property-detail label {
    font-size: 1.2rem;
    color: #323232;
}

.see-listings {
    color: #B19977;
    border: 1px solid #B19977;
    border-radius: 10px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 1rem;
    width: max-content;
}

.property_lowest_price:hover {
    text-decoration: underline;
}

.story-image-container {
  overflow: hidden;
  height: 280px;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 24px;
}

.spec-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-bottom: 1px solid #d3d3d3;
}

.spec-section-name {
    font-size: 0.8rem;
    color: #323232;
}

.spec-section-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
}

.hoodmaps-container {
    margin-bottom: 3rem;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 400px;
}

iframe {
    position: absolute;
    top: -250px;
    left: -100px;
    width: calc(100% + 200px);
    height: calc(100% + 550px);
}

@media (max-width: 768px) {

    .right-overlay {
        width: 100vw;
        height: 35vh;
        position: fixed;
        top: auto;
        bottom: 0;
        z-index: 999;
        border-radius: 20px 20px 0 0;
        background-color: rgba(255, 255, 255);
        align-items: center;
    }

    .right-overlay.hidden {
        transform: translateY(80%);
        transition: transform 0.3s ease;
        }

    .map-story {
        width: 100%;
        top: auto;
        bottom: 0;
        height: 100%;
        max-height: 35vh;
        position: absolute;
        background-color: transparent;
    }

    .page-cover {
        padding-left: 30px;
        padding-right: 30px;
        height: auto;
    }

    .story {
        padding-left: 30px;
        padding-right: 30px;
        height: auto;
    }

    .toggle-overlay {
        display: none;
    }

    .toggle-overlay-mobile {
        height: 100%;
        width: 100%;
        position: relative;
        display: none;
        cursor: pointer;
        font-size: 12px;
        color: white;
        flex-direction: row;
        justify-content: center;
    }

    .toggle-overlay-mobile .toggle-wrapper {
        position: absolute;
        display: flex;
        top: -10%;
        left: auto;
        right: auto;
        width: 60px;
        height: 100px;
    }

    .toggle-overlay-mobile #toggle-text {
        transform: none;
    }

    #toggle {
        height: 30px;
        width: auto;
    }

    #toggle-text {
        left: auto;
        top: 14%;
        font-size: 12px;
    }

    .triangle {
        left: auto;
        top: 6%;
        width: 5px;
        height: auto;
    }

    .cover-title {
        font-size: 32px;
    }

    .story-title {
        font-size: 28px;
    }

    .story {
        font-size: 14px;
        padding-right: 50px;
        padding-bottom: 100px;
    }

    .story-description {
        font-size: 14px;
    }

    .property-detail img {
        height: 16px;
    }

    .story-navigation {
        height: 100%;
        width: 35px;
        display: flex;
        height: 160px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .navigation-container {
        height: 160px;
    }

    .navigation {
        width: 10px;
        height: 10px;
    }

    .expander {
        height: 20px;
        width: 100%;
        display: flex;
        position: fixed;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }

    .expander-line {
        height: 3px;
        width: 80px;
        background-color: #d8d8d8;
        border-radius: 5px;
        display: flex;
        position: fixed;
    }

    .menu-dropdown {
        font-size: 12px;
    }



}

</style>