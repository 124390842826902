import { defineStore } from 'pinia'
import axios from 'axios';
export const useListingsStore = defineStore('listings', {
  state: () => ({
    listings: [],
    isLoading: false,
    lastVisibleId: null,
    allDataLoaded: false
  }),

  actions: {
    setListings(listings) {
      this.listings = listings;
    },

    async fetchListingsByIds(ids, limit = 6, lastVisibleId = null) {
      this.isLoading = true;
      const params = new URLSearchParams();
      if (limit) params.append('limit', limit);
      if (lastVisibleId) params.append('lastVisibleId', lastVisibleId);

      return axios.post(
        `https://us-central1-realsqm-8ca89.cloudfunctions.net/api/villas/ids?${params.toString()}`, 
        { listingIds: ids }
      )
        .then(response => {
          this.listings.push(...response.data.villas);
          this.lastVisibleId = response.data.lastVisibleId;
          if (response.data.villas.length < limit) {
            this.allDataLoaded = true;
          }
          return response.data;
        })
        .catch(() => {
          this.listings = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
})