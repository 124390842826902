import { defineStore } from 'pinia'
import { useUserStore } from './user'
import axios from "axios";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../firebase';

export const useFavouriteStore = defineStore('favourite', {
  state: () => ({
    favourites: [],
    error: null,
    isLoading: true
  }),
  actions: {
    setFavourite(favourites) {
      this.favourites = favourites;
    },

    async fetchFirebaseUser() {
      const userStore = useUserStore();
      
      // Wait for auth state to be determined
      return new Promise((resolve) => {
        const auth = getAuth(app);
        onAuthStateChanged(auth, (user) => {
          userStore.setUser(user);
          resolve(user);
        });
      });
    },

    loadLocalFavourites() {
      const localFavourites = localStorage.getItem('favourites');
      if (localFavourites) {
        this.favourites = JSON.parse(localFavourites);
      }
    },

    saveLocalFavourites() {
      localStorage.setItem('favourites', JSON.stringify(this.favourites));
    },

    async fetchFavourites() {
      //this.loadLocalFavourites();

      const user = await this.fetchFirebaseUser();
      
      if (!user) {
        this.loadLocalFavourites();
        this.isLoading = false;
        return;
      }

      const token = await user.getIdToken();
      const localFavourites = JSON.parse(localStorage.getItem('favourites') || '[]');

      try {
        // Fetch client data from API
        const response = await axios.get("https://us-central1-realsqm-8ca89.cloudfunctions.net/api/favourites", {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        });

        // Merge server favorites with local favorites
        const serverFavourites = response.data.favourites;
        const mergedFavourites = [...new Set([...serverFavourites, ...localFavourites])];
        
        // If there were local favorites, save the merged list to the server
        if (localFavourites.length > 0) {
          for (const listing of localFavourites) {
            await this.addFavourite(listing);
          }
          // Clear local storage after successful sync
          localStorage.removeItem('favourites');
        }

        this.setFavourite(mergedFavourites);
      } catch (error) {
        this.error = error.response?.data?.error || error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async addFavourite(listing) {
      const user = await this.fetchFirebaseUser();
      
      if (!user) {
        this.favourites.push(listing);
        this.saveLocalFavourites();
        return;
      }

      const token = await user.getIdToken();

      try {
        // Send listing data to API
        const response = await axios.post(
          "https://us-central1-realsqm-8ca89.cloudfunctions.net/api/favourites",
          { listing },
          {
            headers: {
              Authorization: `Bearer ${token}`, 
            },
          }
        );

        if (response.status === 200) {
          this.favourites.push(listing)
        }

      } catch (error) {
        this.error = error.response.data.error;
      }
    },

    async removeFavourite(listing) {
      const user = await this.fetchFirebaseUser();

      if (!user) {
        this.favourites = this.favourites.filter(item => item !== listing);
        this.saveLocalFavourites();
        return;
      }

      const token = await user.getIdToken();

      try {
        
        const response = await axios.delete(
          "https://us-central1-realsqm-8ca89.cloudfunctions.net/api/favourites",
          {
            headers: {
              Authorization: `Bearer ${token}`, 
            },
            data: { listing }
          }
        );

        if (response.status === 200) {
          this.favourites = this.favourites.filter(item => item !== listing)
        }

      } catch (error) {
        this.error = error.response.data.error;
      }
    }
  }
})