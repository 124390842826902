import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously, signInWithEmailAndPassword } from 'firebase/auth';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqdLe0HptaY-hmGCYfCQh6LE9BJ45r72I",
  authDomain: "realsqm-8ca89.firebaseapp.com",
  projectId: "realsqm-8ca89",
  storageBucket: "realsqm-8ca89.appspot.com",
  messagingSenderId: "854194384504",
  appId: "1:854194384504:web:f1ddbe4cf197ffe58dc78d",
  measurementId: "G-9KWNMPS639"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the auth service
const auth = getAuth(app);

// Export the app instance along with other exports
export { app, auth, signInAnonymously, signInWithEmailAndPassword };