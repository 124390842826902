<template>
    <div class="tab-title">
        <h1>My Searches</h1>
    </div>
    <div class="searches-container">
        <div class="search-item" v-for="search in savedSearchStore.savedSearches" :key="search.name" @click="goToSearchPage(search)">
            <div class="search-item-header">
                <div class="header-content">
                    <div class="search-item-icon">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2349 19C15.0999 19 15.5569 20.024 14.9799 20.668C14.6048 21.0871 14.1455 21.4223 13.632 21.6518C13.1185 21.8813 12.5624 22 11.9999 22C11.4375 22 10.8814 21.8813 10.3679 21.6518C9.85441 21.4223 9.3951 21.0871 9.01995 20.668C8.46795 20.052 8.86195 19.089 9.65395 19.007L9.76395 19.001L14.2349 19ZM11.9999 2C13.3579 2 14.5059 2.903 14.8749 4.141L14.9209 4.312L14.9289 4.355C16.0315 4.97671 16.971 5.85086 17.6705 6.9058C18.3699 7.96073 18.8094 9.16641 18.9529 10.424L18.9809 10.711L18.9999 11V13.931L19.0209 14.067C19.1579 14.8038 19.5656 15.4627 20.1639 15.914L20.3309 16.031L20.4929 16.13C21.3529 16.617 21.0529 17.896 20.1159 17.994L19.9999 18H3.99995C2.97195 18 2.61295 16.636 3.50695 16.13C3.88798 15.9144 4.21719 15.618 4.47149 15.2616C4.72578 14.9052 4.89899 14.4974 4.97895 14.067L4.99995 13.924L5.00095 10.954C5.06192 9.64788 5.44211 8.37661 6.10823 7.25147C6.77436 6.12632 7.70611 5.18161 8.82195 4.5L9.06995 4.354L9.07995 4.311C9.22101 3.71283 9.54238 3.17241 10.0006 2.76285C10.4588 2.35328 11.0318 2.09432 11.6419 2.021L11.8239 2.004L11.9999 2Z" fill="#B19977"/>
                        </svg>
                    </div>
                    <span class="search-name">{{ search.name }}</span>
                </div>
                <div class="action-icons">
                    <div class="action-icon">
                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H17C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V11.737C18 11.4718 18.1054 11.2174 18.2929 11.0299C18.4804 10.8424 18.7348 10.737 19 10.737C19.2652 10.737 19.5196 10.8424 19.7071 11.0299C19.8946 11.2174 20 11.4718 20 11.737V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.316071 18.5587 0 17.7956 0 17V3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H8.017C8.28222 0 8.53657 0.105357 8.72411 0.292893C8.91164 0.48043 9.017 0.734784 9.017 1C9.017 1.26522 8.91164 1.51957 8.72411 1.70711C8.53657 1.89464 8.28222 2 8.017 2H3Z" fill="#B19977"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4111 0.571993C19.5911 0.752513 19.6922 0.997052 19.6922 1.25199C19.6922 1.50693 19.5911 1.75147 19.4111 1.93199L10.6391 10.718C10.55 10.8072 10.4441 10.8779 10.3276 10.9262C10.2111 10.9745 10.0862 10.9993 9.96011 10.9993C9.834 10.9993 9.70913 10.9745 9.59262 10.9262C9.47612 10.8779 9.37026 10.8072 9.28111 10.718C9.10109 10.5375 9 10.2929 9 10.038C9 9.78305 9.10109 9.53851 9.28111 9.35799L18.0541 0.571993C18.2341 0.392155 18.4782 0.291138 18.7326 0.291138C18.9871 0.291138 19.2311 0.392155 19.4111 0.571993Z" fill="#B19977"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.04 0C19.57 0 20 0.43 20 0.962V6C20 6.531 19.53 7 19 7C18.47 7 18 6.531 18 6V2H14C13.47 2 13 1.531 13 1C13 0.469 13.43 0 13.96 0H19.04Z" fill="#B19977"/>
                        </svg>
                    </div>
                    <div class="action-icon">
                        <svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.19667 17.0217 1.00067 16.5507 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.021 14.413 17.413C14.0217 17.805 13.5507 18.0007 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#B19977"/>
                    </svg>
                    </div>
                </div>
            </div>
            <div class="search-params">
                    <div v-for="(value, key) in search.search" :key="key" class="search-param">
                        <p><strong>{{ key }}:</strong> {{ value }}</p>
                    </div>
                </div>
            </div>
    </div>
</template>

<script setup>
import { useSavedSearchStore } from '@/stores/savedSearches';
import { useRouter } from 'vue-router';
const savedSearchStore = useSavedSearchStore();
const router = useRouter();

savedSearchStore.fetchSavedSearches();

const goToSearchPage = (search) => {
    router.push({
        path: '/search',
        query: search.search
    });
}

</script>

<style>

.searches-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 2rem;
}

.search-item {
    border: 1px solid #B19977;
    border-radius: 10px;
    padding: 1rem;
    width: 70%;
    gap: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.search-name {
    font-weight: bold;
    font-size: 20px;
}

.search-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    padding: 0.5rem;
    border: 1px solid #B19977;
}

.header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.search-item svg {
    height: 2rem;
}

.search-item-header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.search-params {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.search-param {
    background-color: #F5F5F5;
    padding: 0.5rem;
    border-radius: 10px;
    border: 1px solid #B19977;
    font-size: 12px;
}

.action-icons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.action-icon {
    border: 1px solid #B19977;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-icon svg {
    height: 10px;
}

</style>
