<template>
  <div class="list-container" ref="container">
    <div class="real-estate-list" v-if="properties.length > 0" ref="list" :filter="query">
      <div v-for="(property, index) in properties" :key="property.id" class="property-card" @click.stop="viewDetails(property)">
        <div class="image-container" :ref="`imageContainer-${index}`">
          <img 
            :src="property.activeImageIndex === 0 ? property.images[0] : ''" 
            :data-src="property.images[property.activeImageIndex]"
            :alt="`Image of ${property.location}`" 
            class="property-image"
            loading="lazy"
          />
          <div class="overlay"></div>
          <div class="image-navigation-container">
            <button class="arrow-left" @click.stop="previousImage(index)">
              <img src="../assets/chevron-left.svg" alt="previousImage">
            </button>
            <button class="arrow-right" @click.stop="nextImage(index)">
              <img src="../assets/chevron-right.svg" alt="nextImage">
            </button>
          </div>
          <div class="favourite-icon">
            <svg v-if="property.isFavorite" @click.stop="toggleFavorite(property)" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 18.35L8.55 17.03C3.4 12.36 0 9.27 0 5.5C0 2.41 2.42 0 5.5 0C7.24 0 8.91 0.81 10 2.08C11.09 0.81 12.76 0 14.5 0C17.58 0 20 2.41 20 5.5C20 9.27 16.6 12.36 11.45 17.03L10 18.35Z" fill="#FFFFFF"/>
            </svg>
            <svg v-else @click.stop="toggleFavorite(property)" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.1 15.55L10 15.65L9.89 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 3 9.07 4.36H10.93C11.46 3 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55ZM14.5 0C12.76 0 11.09 0.81 10 2.08C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.41 0 5.5C0 9.27 3.4 12.36 8.55 17.03L10 18.35L11.45 17.03C16.6 12.36 20 9.27 20 5.5C20 2.41 17.58 0 14.5 0Z" fill="#FFFFFF"/>
            </svg>
          </div>
        </div>
        <div class="property-info">
          <div class="location">{{ property.location }}</div>
          <div class="property-details">
              <img src="../assets/size.png">
              <span v-if="property.building_size_sqm !== 0">{{ property.land_size_sqm }} sqm (Land), {{ property.building_size_sqm }} sqm (Building)</span>
              <span v-else>{{ property.land_size_sqm }} sqm (Land)</span>
          </div>
          <div class="property-details">
              <img v-if="property.bedrooms !== 0" src="../assets/bedroom.png">
              <span v-if="property.bedrooms !== 0">{{ property.bedrooms }}</span>
              <span v-if="property.bedrooms !== 0 && property.bathrooms !== 0" class="divider">|</span>
              <img v-if="property.bathrooms !== 0" src="../assets/bathroom.png">
              <span v-if="property.bathrooms !== 0">{{ property.bathrooms }}</span>
          </div>
          <div class="property-price">
              <div>{{ formatPrice(property, 'total') }}</div>
              <div class="property-price-sqm" v-if="formatPrice(property, 'sqm') != 0">/ {{ formatPrice(property, 'sqm') }} per sqm</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="loading">
      <img :src="loadingGif" alt="Loading more properties...">
    </div>
    <button 
      v-if="properties.length > 0 && !allDataLoaded && !isLoading" 
      class="load-more-button" 
      @click="loadMore"
    >
      Load More
    </button>
    <div class="no-result" v-if="(properties.length === 0 && !isLoading) || (properties.length === 0 && !listingsStore.isLoading)">
      <div>No result found</div>
      <div class="see-all-properties" @click="seeAllProperties">See all properties</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import loadingGif from '@/assets/loading.svg';
import Hammer from 'hammerjs';
import { useFavouriteStore } from '@/stores/favourite';
import { useListingsStore } from '@/stores/listings';

export default {
 
  data() {
    return {
      properties: [],
      lastVisibleId: null,
      isLoading: false,
      allDataLoaded: false,
      listingId: '',
      defaultCurrency: 'EUR',
      loadingGif,
      favouriteStore: useFavouriteStore(),
      listingsStore: useListingsStore(),
      debounceTimeout: null
    };
  },

  props: {
    query: {
      type: Object,
      default: () => ({})
    },

    itemsPerPage: {
      type: Number,
      default: 6,
    },

    loadOnScroll: {
      type: Boolean,
      default: true,
    },

    favouritesPage: {
      type: Boolean,
      default: false,
    }
  },
  
  computed: {

    watchedQuery() {
      return {
        region: this.query.region || '',
        type: this.query.type || '',
        minPrice: this.query.minPrice || '',
        maxPrice: this.query.maxPrice || '',
        currency: this.query.currency || '',
        buildingSize: this.query.buildingSize || '',
        landSize: this.query.landSize || '',
        bedroom: this.query.bedroom || '',
        bathroom: this.query.bathroom || '',
        sort: this.query.sort || '',
      };
    },

    currentFilters() {
      return this.filters;
    },

    ...mapState({
        filters: state => state.filters,
      }),

    currency() {
      return this.$route.query.currency || this.defaultCurrency;
    },
  },

  watch: {
    watchedQuery: {
      handler(newVal, oldVal) {
        if (!newVal || !oldVal) {
          return;
        }
        // Condition 1: Check if filters have changed
        const basicParamsChanged = newVal.region !== oldVal.region ||
                                  newVal.type !== oldVal.type ||
                                  newVal.minPrice !== oldVal.minPrice ||
                                  newVal.maxPrice !== oldVal.maxPrice ||
                                  newVal.buildingSize !== oldVal.buildingSize ||
                                  newVal.landSize !== oldVal.landSize ||
                                  newVal.bedroom !== oldVal.bedroom ||
                                  newVal.bathroom !== oldVal.bathroom ||
                                  newVal.sort !== oldVal.sort;

        // Condition 2: Check if currency has changed AND either minPrice or maxPrice is present
        const currencyChangedWithPrice = (newVal.currency !== oldVal.currency) &&
                                        (newVal.minPrice || newVal.maxPrice);

        // Condition 3: Identify if the only query param is currency
        const onlyCurrencyPresent = Object.keys(newVal).length === 1 && newVal.currency;

        // Execute the code only if conditions 1 or 2 are met, and condition 3 is not met
        if ((basicParamsChanged || currencyChangedWithPrice) && !onlyCurrencyPresent) {
          this.allDataLoaded = false;
          this.lastVisibleId = null;
          this.properties = [];
          this.getProperties();
        }

      },
      deep: true,
      immediate: true,
    },

    'listingsStore.listings': {
      handler(newListings) {
        if (this.favouritesPage) {
          this.properties = newListings.map(property => ({
            ...property,
            activeImageIndex: 0,
            isFavorite: this.favouriteStore.favourites.includes(property.listing_id)
          }));

          this.$nextTick(() => {
            this.initSwipe();
            this.initLazyLoading();
          });
        }
      },
      deep: true
    },

  },

  shouldFetch(newVal) {
    if (newVal) {
      this.getProperties();
      }
    return this.$store.state.isLoading;
    },
  
  async mounted() {
    await this.favouriteStore.fetchFavourites();
    const ids = this.favouriteStore.favourites;

    if (this.favouritesPage) {
      if (this.listingsStore.isLoading || this.listingsStore.allDataLoaded) return;

      this.isLoading = true;

      await this.listingsStore.fetchListingsByIds(ids, this.itemsPerPage, this.listingsStore.lastVisibleId).then(() => {
        this.isLoading = false;
      });

      this.properties = this.listingsStore.listings.map(property => ({
        ...property,
        activeImageIndex: 0,
        isFavorite: this.favouriteStore.favourites.includes(property.listing_id)
      }));
    } else {
      this.initializeFiltersFromQuery();
      this.getProperties();
    }
  },

  methods: {
    loadMore() {
      if (this.isLoading || this.allDataLoaded) return;
      
      if (this.favouritesPage) {
        this.listingsStore.fetchListingsByIds(
          this.favouriteStore.favourites,
          this.itemsPerPage,
          this.listingsStore.lastVisibleId
        );
      } else {
        this.getProperties();
      }
    },

    getListingsByIds(ids) {
      axios.get(`https://us-central1-realsqm-8ca89.cloudfunctions.net/api/villas?ids=${ids.join(',')}`).then(response => {
        this.properties = response.data.villas;
      });
    },

    getProperties() {
      if (this.isLoading || this.allDataLoaded) return;

        this.isLoading = true;

      const params = new URLSearchParams({
        limit: this.itemsPerPage,
        ...(this.lastVisibleId && { lastVisibleId: this.lastVisibleId }),
      });


      params.append('region', this.filters.region || '');
      params.append('type', this.filters.type || '');
      params.append('minPrice', this.filters.minPrice || '');
      params.append('maxPrice', this.filters.maxPrice || '');
      params.append('currency', this.filters.currency || '');
      params.append('buildingSize', this.filters.buildingSize || '');
      params.append('landSize', this.filters.landSize || '');
      params.append('bedroom', this.filters.bedroom || '');
      params.append('bathroom', this.filters.bathroom || '');
      params.append('sort', this.filters.sort || '');
      
      axios.get(`https://us-central1-realsqm-8ca89.cloudfunctions.net/api/villas?${params.toString()}`)
        .then(response => {
          if (response.data.villas.length < this.itemsPerPage) {
            this.allDataLoaded = true;
          }

          this.properties.push(...response.data.villas);
          this.lastVisibleId = response.data.lastVisibleId;
          this.$store.dispatch('saveSearchResultInfo', { name: 'regionName', value: this.filters.region });
          this.$store.dispatch('saveSearchResultInfo', { name: 'totalProperties', value: response.data.total });

          this.properties = this.properties.map(property => ({
            ...property,
            activeImageIndex: 0,
            isFavorite: this.favouriteStore.favourites.includes(property.listing_id)
          }));

          this.$nextTick(() => {
            this.initSwipe();
            this.initLazyLoading();
          });
        })

        .catch(error => {
          console.error('Error fetching properties:', error);
        })

        .finally(() => {
          this.isLoading = false;
        });
    },

    viewDetails(property) {
        localStorage.setItem('selectedListing', JSON.stringify(property));

        const searchParams = new URLSearchParams(this.$route.query).toString();
        const url = `/listing/${property.listing_id}?${searchParams && '&' + searchParams}`;

        if (window.innerWidth < 768) {
            window.location.href = url;
        } else {
            window.open(url, '_blank');
        }
    },

    formatPrice(property, type='total') {
      let priceField;
      const currency = this.currency.toUpperCase();

      if (type === 'total') {
        priceField = `price_${currency.toLowerCase()}`
      } else if (type === 'sqm') {
        priceField = `price_per_sqm_${currency.toLowerCase()}`
      }

      const price = property[priceField];
      let formatted;
      let converted;

      
      // Format price when it is not available
      if (price === 0 || price === null || price === undefined) {

        // Total price
        if (type == 'total') {
          formatted = "Contact us for price";

        // Price per sqm
        } else formatted = "";
          
      }

      // Format price when it is not BTC
      if (currency.toLowerCase() != 'btc' && price > 0) {
        if (price > 0 && price < 1000) {
          formatted = `${currency.toUpperCase()} ${price}`
        } else  if (price >= 1000 && price < 1000000) {
          converted = `${(price / 1000).toFixed(2)}k`;
          formatted = `${currency.toUpperCase()} ${converted}`
        } else if (price >= 1000000 && price <= 1000000000) {
          converted = `${(price / 1000000).toFixed(2)}m`;
          formatted = `${currency.toUpperCase()} ${converted}`
        } else if (price > 1000000000 && price <= 1000000000000) {
          converted = `${(price / 1000000000).toFixed(2)}b`;
          formatted = `${currency.toUpperCase()} ${converted}`
        } else if (price > 1000000000000 && price <= 1000000000000000) {
          converted = `${(price / 1000000000000).toFixed(2)}t`;
          formatted = `${currency.toUpperCase()} ${converted}`
        } 
      } else if (currency.toLowerCase() === 'btc' && price > 0) {
        let formatted_raw = new Intl.NumberFormat('en-US').format(price);
        formatted = `${currency} ${(formatted_raw)}`
      }
      
      return formatted;

    },

    filtersDiffer(newFilters, oldFilters) {
      return ['type', 'minPrice', 'maxPrice', 'buildingSize', 'landSize', 'sort'].some(filterKey => newFilters[filterKey] !== oldFilters[filterKey]);
    },

    initializeFiltersFromQuery() {
      if (this.query.region) {
        this.filters.region = this.query.region;
      }
      if (this.query.type) {
        this.filters.type = this.query.type;
      }
      if (this.query.minPrice) {
        this.filters.minPrice = this.query.minPrice;
      }
      if (this.query.maxPrice) {
        this.filters.maxPrice = this.query.maxPrice;
      }
      if (this.query.currency) {
        this.selectedCurrency = this.query.currency.toUpperCase();
        this.$store.dispatch('applyFilter', { filterName: 'currency', value: this.selectedCurrency });
      }
      if (this.query.buildingSize) {
        this.filters.buildingSize = this.query.buildingSize;
      }
      if (this.query.landSize) {
        this.filters.landSize = this.query.landSize;
      }
      if (this.query.bedroom) {
        this.filters.bedroom = this.query.bedroom;
      }
      if (this.query.bathroom) {
        this.filters.bathroom = this.query.bathroom;
      }
      if (this.query.sort) {
        this.filters.sort = this.query.sort;
      }
    },

    previousImage(propertyIndex) {
      const property = this.properties[propertyIndex];
      if (property.activeImageIndex > 0) {
        property.activeImageIndex -= 1;
        this.$nextTick(() => {
          const container = this.$refs[`imageContainer-${propertyIndex}`][0];
          const img = container.querySelector('.property-image');
          img.src = property.images[property.activeImageIndex];
        });
      } else {
        property.activeImageIndex = property.images.length - 1;
        this.$nextTick(() => {
          const container = this.$refs[`imageContainer-${propertyIndex}`][0];
          const img = container.querySelector('.property-image');
          img.src = property.images[property.activeImageIndex];
        });
      }
    },

    nextImage(propertyIndex) {
      const property = this.properties[propertyIndex];
      if (property.activeImageIndex < property.images.length - 1) {
        property.activeImageIndex += 1;
        this.$nextTick(() => {
          const container = this.$refs[`imageContainer-${propertyIndex}`][0];
          const img = container.querySelector('.property-image');
          img.src = property.images[property.activeImageIndex];
        });
      } else {
        property.activeImageIndex = 0;
        this.$nextTick(() => {
          const container = this.$refs[`imageContainer-${propertyIndex}`][0];
          const img = container.querySelector('.property-image');
          img.src = property.images[0];
        });
      }
    },

    initSwipe() {
        this.properties.forEach((property, index) => {
            const imageContainers = this.$refs[`imageContainer-${index}`];
            if (imageContainers && imageContainers[0]) { 
                const mc = new Hammer(imageContainers[0]);
                mc.on("swipeleft", () => this.nextImage(index));
                mc.on("swiperight", () => this.previousImage(index));
            }
        });
    },

    seeAllProperties() {
      const cur = this.currency
      window.location.href = `/search?currency=${cur}`;
    },

    initLazyLoading() {
      const options = {
        root: null,
        rootMargin: '50px',
        threshold: 0.1
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const img = entry.target;
            if (img.dataset.src) {
              img.src = img.dataset.src;
            }
            observer.unobserve(img);
          }
        });
      }, options);

      // Observe all property images
      document.querySelectorAll('.property-image').forEach(img => {
        observer.observe(img);
      });
    },

    async toggleFavorite(property) {
      // Toggle the favorite state immediately
      property.isFavorite = !property.isFavorite;
      
      try {
        if (property.isFavorite) {
          await this.favouriteStore.addFavourite(property.listing_id);
        } else {
          await this.favouriteStore.removeFavourite(property.listing_id);
        }
      } catch (error) {
        // Revert the UI state if the API call fails
        property.isFavorite = !property.isFavorite;
        console.error('Error toggling favorite:', error);
      }
    },
  }
};
</script>

<style scoped>
.list-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  position: relative;
}

.real-estate-list {
  width: 90%;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin: 0 auto;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .real-estate-list {
    grid-template-columns: 1fr;
    width: 95%;
  }
}

.loading {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.no-result {
  width: 100%;
  padding: 40px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.see-all-properties {
  cursor: pointer;
  background: #B19977;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 800;
  width: 200px;
  padding: 8px 0px 8px 0px;
  height: 30px;
  justify-content: center;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.property-card {
  overflow: hidden;
  border: 0;
  min-width: 250px;
  cursor: pointer;
}

.image-container {
  overflow: hidden;
  height: 280px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 24px;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  pointer-events: none;
}

.image-container:hover .overlay {
  background-color: rgba(0, 0, 0, 0.2);
}

.property-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block; 
}

.property-image[lazy="loading"] {
  width: 50px;
  height: auto;
  margin: auto;
}

.property-info {
  display: flex;
  flex-direction: column;
  padding: 10px 16px 0px 16px;
  font-family: 'Sofia Sans', sans-serif;
  gap: 3px;
}

.location {
  color: #323232;
  font-size: 16px;
}

.property-details {
  color: #A0A0A0;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.property-details img {
  height: 16px;
  margin-right: 10px;
}

.divider {
  margin: 0px 10px 0px 10px; 
}

.property-price {
  color: #3A6083;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  gap: 10px;
}

.property-price-sqm{
  color: #A0A0A0;
  font-size: 12px;
}

.property-price button:hover {
  background-color: #997C54;
}

.favourite-icon {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
}

.favourite-icon svg {
  height: 20px;
}

.image-navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  position: absolute;
  top: 0;
  opacity: 0;
}

.image-navigation-container:hover {
  opacity: 1;
}

.image-navigation-container button {
  height: 30px;
  width: 30px;
  background-color: rgba(235, 233, 233, 0.8);
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0px 15px 0px 15px;
}

.image-navigation-container button img{
  height: 16px;
  margin: auto;
}

.image-navigation-container button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.load-more-button {
  background: #B19977;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  margin: 20px 0;
  padding: 0 30px;
  transition: background-color 0.3s ease;
  margin-bottom: 4rem;
}

.load-more-button:hover {
  background: #997C54;
}

.load-more-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

</style>

