<template>
    <div class="sh-dropdown-menu" v-show="menuStore.isMenuOpen">
        <div class="sh-dropdown-list">
            <div class="menu-line" @click="menuStore.toggleMenu">
                <div class="sh-burger-menu"><img src="@/assets/menu_open.svg"></div>
            </div>
            <div class="group-menu">
              <a class="sh-dropdown-item" target="_blank" @click="loginWithGoogle" v-if="!userStore.user">Login</a>
              <a class="sh-dropdown-item" href="/account/favourites">Favourites</a>
              <a class="sh-dropdown-item" href="/account/saved-searches" v-if="userStore.user">Saved Searches</a>
              <a class="sh-dropdown-item" target="_blank" @click="logout" v-if="userStore.user">Logout</a>
            </div>
            <a class="sh-dropdown-item" href="/">Return to Map</a>
            <a class="sh-dropdown-item" href="/search">Search Property</a>
            <a class="sh-dropdown-item" target="_blank" href="https://dusty-cathedral-26a.notion.site/Behind-Estad-s-Data-and-Methodology-f47ffc8ccf25435498034654a2c5b4fb">Methodology</a>
            <a class="sh-dropdown-item" target="_blank" @click="otherInquiries">Contact us</a>
        </div>
        <div class="dd-footer-container">
            <div class="dd-links">
                <a class="sh-dropdown-item" target="_blank" href="/privacy">Privacy Policy</a>
            </div>
            <div class="dd-footer-social">
                <a class="dd-footer-link" href="https://instagram.com/estad.xyz" target="_blank">
                    <img src="../assets/instagram.png" alt="Instagram">
                </a>
                <a class="dd-footer-link" href="" target="_blank">
                    <img src="../assets/x.png" alt="Twitter">
                </a>  
                <a class="dd-footer-link" href="" target="_blank">
                    <img src="../assets/discord.png" alt="Discord">
                </a>
            </div>
            <div class="dd-copyright">
                <p>Copyright ©2024 by Estad | All rights reserved</p>
            </div>
        </div>  
    </div>
</template>

<script setup>
import { useMenuStore } from '@/stores/menu'
import { useUserStore } from '@/stores/user'

const menuStore = useMenuStore()
const userStore = useUserStore()

const otherInquiries = () => {
  const email = 'arletafenty@gmail.com'
  const subject = 'I would like to reach out'
  const body = ''
  window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`)
}

const loginWithGoogle = async () => {

  menuStore.closeMenu();

  await userStore.loginWithGoogle();
  window.location.reload();
};

const logout = async () => {

  await userStore.logout();
  window.location.reload();
};
</script>

<style scoped>
.sh-dropdown-menu {
  position: fixed;
  background-color: #ffffff;
  padding: 25px 30px 25px 30px;
  width: 300px;
  height: 100vh;;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  text-align: right;
  gap: 15px;
  height: 100vh;
  justify-content: space-between;
  transition: transform 0.3s ease;
  z-index: 3;
}

.sh-dropdown-item {
  border-bottom: 1px solid #DFDFDF;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  cursor: pointer;
}

.sh-dropdown-item:link {
  text-decoration: none;
  color: #323232;
}

.sh-dropdown-item:visited {
  text-decoration: none;
  color: #323232;
}

.sh-dropdown-item:hover {
  color: #B19977;
  font-style: italic;
}
  
.dd-footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  gap: 20px;
}

.dd-footer-social {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.dd-footer-social img {
  height: 30px;
  width: auto;
  margin-left: 15px;
}

.dd-copyright {
  color: #A0A0A0;
  font-size: 14px;
}

.dd-links .sh-dropdown-item {
  border-bottom: none;
  font-size: 12px;
  color: #A0A0A0;
  height: 10px;
}

.group-menu {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {

  .sh-dropdown-menu {
    position: fixed;
    background-color: #ffffff;
    padding: 25px 30px 25px 30px;
    width: 150px;
    }

}


</style>