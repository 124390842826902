<template>
  <div class="dt-property-detail">
    <div class="dt-heading">
      <div class="dt-summary">
        <div @click.prevent="goBack" class="backToSearch">&lt; Return to search</div>
        <div class="dt-price-container">
            <button class="dropdown-filter" ref="currencyDropdownButton" @click.stop="toggleCurrencyDropdown">
              <span>{{ selectedCurrency }}</span>
                <img src="../assets/dropdown.png">
              <div class="dropdown-menu" v-show="isCurrencyDropdownOpen">
                <a class="dropdown-item" href="#" @click.prevent="selectCurrency('IDR')">IDR</a>
                <a class="dropdown-item" href="#" @click.prevent="selectCurrency('EUR')">EUR</a>
                <a class="dropdown-item" href="#" @click.prevent="selectCurrency('USD')">USD</a>
                <a class="dropdown-item" href="#" @click.prevent="selectCurrency('AUD')">AUD</a>
                <a class="dropdown-item" href="#" @click.prevent="selectCurrency('SGD')">SGD</a>
                <a class="dropdown-item" href="#" @click.prevent="selectCurrency('GBP')">GBP</a>
                <a class="dropdown-item" href="#" @click.prevent="selectCurrency('BTC')">BTC</a>
              </div>
            </button>
          <div class="price">{{ formatPrice('total') }}</div>
          <div class="pricesqm" v-if="formatPrice('sqm') != 0">/ {{ formatPrice('sqm') }} per sqm</div>
        </div>
        <div class="overview-wrapper">
          <div class="overview">
          <div class="overview-section">
            <span class="dt-location-container">
              <img src="../assets/location.svg">
              <span class="dt-location" @click.prevent="scrollToMap" ref="mapContainer">{{ listing.region }}</span>
            </span>
          </div>
          <div class="overview-section">
            <span class="dt-sizing">
              <img src="../assets/size.png">
              <span v-if="listing.building_size_sqm !== 0">{{ listing.land_size_sqm }} sqm (Land), {{ listing.building_size_sqm }} sqm (Building)</span>
              <span v-else>{{ listing.land_size_sqm }} sqm (Land)</span>
            </span>
            <span class="dt-rooms">
              <span v-if="listing.bedrooms !== 0 || listing.bathrooms !== 0" class="dt-divider">|</span>
                <img v-if="listing.bedrooms !== 0" src="../assets/bedroom.png">
                <span v-if="listing.bedrooms !== 0">{{ listing.bedrooms }}</span>
                <span v-if="listing.bedrooms !== 0 && listing.bathrooms !== 0" class="dt-divider">|</span>
                <img v-if="listing.bathrooms !== 0" src="../assets/bathroom.png">
                <span v-if="listing.bathrooms !== 0">{{ listing.bathrooms }}</span>
              </span>
          </div>
        </div>
        </div>
      </div>
      <a class="dt-get-contact-link" :href="listing.source_url" target="_blank">
        <div class="dt-get-contact">
          <img src="../assets/mail.png">
          Get Contact
        </div>
      </a>
    </div>
    <div class="all-images-container">
      <div class="dt-image-container">
        <div class="dt-image-main" @click.stop="togglePhotoTour" ref="imageContainer">
          <img v-if="listing.images && listing.images.length > 0" :src="listing.images[activeImageIndex]" alt="main" />
          <div class="dt-overlay"></div>
          <div class="dt-image-navigation-container">
            <button class="arrow-left" @click.stop="previousImage">
              <img src="../assets/chevron-left.svg" alt="previousImage">
            </button>
            <button class="arrow-right" @click.stop="nextImage">
              <img src="../assets/chevron-right.svg" alt="nextImage">
            </button>
          </div>
        </div>
        <div class="dt-image-thumb">
          <div class="dt-image-thumb1" @click.stop="togglePhotoTour">
            <img v-if="listing.images && listing.images.length > 0" :src="listing.images[1]" alt="thumbnail1"/>
            <div class="dt-overlay"></div>
          </div>
          <div class="dt-image-thumb2" @click.stop="togglePhotoTour">
            <img v-if="listing.images && listing.images.length > 0" :src="listing.images[2]" alt="thumbnail2"/>
            <div class="dt-overlay"></div>
          </div>
        </div>
      </div>
      <div class="favourite-icon">
          <svg v-if="isFavorite" @click.stop="toggleFavorite" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 18.35L8.55 17.03C3.4 12.36 0 9.27 0 5.5C0 2.41 2.42 0 5.5 0C7.24 0 8.91 0.81 10 2.08C11.09 0.81 12.76 0 14.5 0C17.58 0 20 2.41 20 5.5C20 9.27 16.6 12.36 11.45 17.03L10 18.35Z" fill="#FFFFFF"/>
          </svg>
          <svg v-else @click.stop="toggleFavorite" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1 15.55L10 15.65L9.89 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 3 9.07 4.36H10.93C11.46 3 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55ZM14.5 0C12.76 0 11.09 0.81 10 2.08C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.41 0 5.5C0 9.27 3.4 12.36 8.55 17.03L10 18.35L11.45 17.03C16.6 12.36 20 9.27 20 5.5C20 2.41 17.58 0 14.5 0Z" fill="#FFFFFF"/>
          </svg>
        </div>
    </div>
    <div class="dt-spec-container">
      <div class="dt-spec-title">Overview</div>
      <div class="overview-container">
        <div class="overview-value">
          <img src="../assets/overview_year.svg">
          <div>{{ listing.year_built || listing.year_built === 0 ? 'N/A' : listing.year_built }}</div>
          <div class="overview-label">Year built</div>
        </div>
        <div class="overview-value">
          <img src="../assets/overview_furnish.svg">
          <div>{{ listing.furnish_status === 'fully furnished' ? 'Fully Furnished' 
          : listing.furnish_status === 'semi furnished' ? 'Semi Furnished' 
          : listing.furnish_status === 'unfurnished' ? 'Unfurnished' 
          : 'N/A' }}</div>
          <div class="overview-label">Furnish Status</div>
        </div>
        <div class="overview-value">
          <img src="../assets/overview_ownership.svg">
          <div>{{ listing.ownership_type || listing.ownership_type === 'lease hold' ? 'Leasehold' : 'Freehold' }}</div>
            <div class="overview-label">Ownership Type</div>
        </div>
        <div class="overview-value">
          <img src="../assets/overview_tenure.svg">
          <div v-if="listing.ownership_tenure > 0">{{ listing.ownership_tenure }} years</div>
          <div v-else>N/A</div>
          <div class="overview-label">Tenure</div>
        </div>
      </div>
      <div class="dt-spec-title">Features</div>
      <div class="dt-features" v-if="availableFeatures.length > 0">
        <div v-for="(feature, key) in featuresMap" :key="key" class="dt-feature-item" :class="{ disabled: !availableFeatures.includes(key) }">
          <img :src="feature.img" :alt="feature.label">
          <span>{{ feature.label }}</span>
        </div>
      </div>
      <div v-else class="dt-features">
        Contact us for more detail.
      </div>
        <div class="dt-spec-title">Description</div>
        <div v-if="!isLoading">
          <div class="dt-description" v-html="formattedText"></div>
        </div>
        <div v-else>Loading...</div>
        <div class="dt-spec-title">Location</div>
        <div class="dt-map-container" ref="mapContainer">
          <div id="dt-map"></div>
        </div>
        <div class="dt-spec-title">You might be interested in</div>
        <PropertyList :itemsPerPage="3" :loadOnScroll="false" :query="$route.query"/>
    </div>
    <a class="dt-get-contact-mobile-link" :href="listing.source_url" target="_blank">
      <div class="dt-get-contact-mobile">
        <img src="../assets/mail.png">
        Get Contact
      </div>
    </a>
    <transition name="slide-up">
      <PhotoTour v-if="showPhotoTour" @close="togglePhotoTour" :images="listing.images" />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PhotoTour from './PhotoTour.vue';
import PropertyList from './PropertyList.vue';
import mapboxgl from 'mapbox-gl';
import points from '@/assets/points.json';
import Hammer from 'hammerjs';
import { useFavouriteStore } from '@/stores/favourite';
export default {

  components: {
    PhotoTour,
    PropertyList
  },

  data() {
    return {
      isCurrencyDropdownOpen: false,
      activeImageIndex: 0,
      showPhotoTour: false,
      featuresMap: {
        'wifi': { img: require('@/assets/spec_wifi.svg'), label: 'WiFi' },
        'pool': { img: require('@/assets/spec_pool.svg'), label: 'Pool' },
        'ac': { img: require('@/assets/spec_ac.svg'), label: 'Air Conditioner' },
        'parking': { img: require('@/assets/spec_parking.svg'), label: 'Parking' },
        'garage': { img: require('@/assets/spec_garage.svg'), label: 'Garage' },
        'gym': { img: require('@/assets/spec_gym.svg'), label: 'Gym' },
        'maid_room': { img: require('@/assets/spec_maidroom.svg'), label: 'Maid Room' },
        'security': { img: require('@/assets/spec_security.svg'), label: 'Security' },
      },
      defaultCurrency: 'EUR',
      descriptionText: '',
      isLoading: true,
      currentMapCenter: [115.182854, -8.720991],
      map: null,
      favouriteStore: useFavouriteStore(),
      isFavorite: false,
    };
  },

  computed: {

    ...mapState({
        listing: state => state.selectedListing,
      }),

    availableFeatures() {
      return this.listing && this.listing.specs ? this.listing.specs : [];
    },

    selectedCurrency() {
      return this.$route.query.currency || this.defaultCurrency;
    },

    formattedText() {
      if (this.isLoading) return '';
      return this.formatDescription(this.listing.description);
    },
  },

  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('click', this.handleClickOutside);

    await this.favouriteStore.fetchFavourites();

    const selectedListing = JSON.parse(localStorage.getItem('selectedListing'));

    localStorage.removeItem('selectedListing');

    if (selectedListing) {
      this.isLoading = true;
      this.$store.dispatch('storeListingData', selectedListing).then(() => {
        this.isLoading = false;
        this.preloadAllImages();
        this.$nextTick(() => {
              this.initSwipe();
          });
        this.initMap();
      });
      this.isFavorite = this.favouriteStore.favourites.includes(selectedListing.listing_id);
    } else {
      this.isLoading = true;
      const listingId = this.$route.params.listingId;
      if (listingId) {
        this.$store.dispatch('fetchListingById', listingId).then(() => {
          this.isLoading = false;
          this.$nextTick(() => {
              this.initSwipe();
          });
          this.initMap();
        }).catch((error) => {
          console.error('Error fetching listing:', error);
          this.isLoading = false;
        });

        this.isFavorite = this.favouriteStore.favourites.includes(parseInt(listingId));
      } else {
        console.error('No listing ID provided in route params');
        this.isLoading = false;
      }
    }
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    toggleCurrencyDropdown() {
      this.isCurrencyDropdownOpen = !this.isCurrencyDropdownOpen;
    },

    formatPrice(type='total') {
      let priceField;
      const currency = this.selectedCurrency.toUpperCase();

      if (type === 'total') {
        priceField = `price_${currency.toLowerCase()}`
      } else if (type === 'sqm') {
        priceField = `price_per_sqm_${currency.toLowerCase()}`
      }

      const price = this.listing[priceField];
      let formatted;

      // Format price when it is not available
      if (price === 0 || price === null || price === undefined) {

        // Total price
        if (type === 'total') {
          formatted = "Contact us for price";

        // Price per sqm
        } else formatted = "";
          
        }

      if (currency.toLowerCase() != 'btc' && price > 0) {
        formatted = new Intl.NumberFormat('en-US').format(Math.round(price));
      } else if (currency.toLowerCase() === 'btc' && price > 0) {
        formatted = new Intl.NumberFormat('en-US').format((price));
      }

        return formatted;
    },

    selectCurrency(currency) {
      this.$router.push({ 
        query: { 
          ...this.$route.query,
          currency: currency
        } 
      });
    },

    handleClickOutside(event) {
      if (this.$refs.currencyDropdownButton && !this.$refs.currencyDropdownButton.contains(event.target)) {
        this.isCurrencyDropdownOpen = false;
      }
    },

    previousImage() {
      if (this.activeImageIndex > 0) {
        this.activeImageIndex -= 1;
      } else {
        this.activeImageIndex = this.listing.images.length - 1;
      }
    },

    nextImage() {
      if (this.activeImageIndex < this.listing.images.length - 1) {
        this.activeImageIndex += 1;
      } else {
        this.activeImageIndex = 0;
      }
    },

    togglePhotoTour() {
      this.showPhotoTour = !this.showPhotoTour;
    },

    formatDescription(descriptionText) {
      if (!descriptionText) { 
        return '';
      }
      const sentences = descriptionText.split('. ');
      let formattedText = '';
      
      for (let i = 0; i < sentences.length; i++) {
        if (sentences[i].trim() === '') continue; // Skip empty entries resulting from split
        formattedText += sentences[i].trim() + '.';
        if ((i + 1) % 2 === 0 && i < sentences.length - 1) { // After every second sentence, add a break
          formattedText += '<br><br>'; // For HTML
          // formattedText += '\n\n'; // For plain text, uncomment this line and comment out the line above
        } else if (i < sentences.length - 1) { // Add space if it's not the last sentence
          formattedText += ' ';
        }
      }
      
      return formattedText;
    },


    scrollToMap() {
      const mapContainer = this.$refs.mapContainer;
      mapContainer.scrollIntoView({ behavior: 'smooth' });

      this.flyToCoordinate();
    },

    flyToCoordinate() {
      const mapContainer = this.$refs.mapContainer;
      
      if (mapContainer && this.map) {
        this.map.flyTo({
          center: this.currentMapCenter,
          zoom: 14,
          essential: true
        });
      }
    },

    initMap() {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

        const bounds = [
          [114.316605, -8.899764],
          [115.852038, -7.972213]
        ];

        this.map = new mapboxgl.Map({
            container: 'dt-map',
            style: process.env.VUE_APP_MAPBOX_MAP_STYLE_LIGHT,
            center: this.currentMapCenter,
            zoom: 11.0,
            bearing: 0.00,
            pitch: 0.00,
            maxBounds: bounds
        });

        this.map.on('load', () => {

          // Filter the points based on the listing's region
          const filteredPoints = {
            ...points,
            features: points.features.filter(feature => feature.properties.name === this.listing.region)
          };

          if (this.map.getSource('region')) { 
              this.map.removeSource('region');
          }

          this.map.addSource('region', {
              'type': 'geojson',
              'data': filteredPoints
          });

          if (filteredPoints.features.length > 0) {
            const feature = filteredPoints.features[0]; // Focus on the first feature
            const [lng, lat] = feature.geometry.coordinates;

            new mapboxgl.Marker()
              .setLngLat([lng, lat])
              .addTo(this.map);

            this.currentMapCenter = [lng, lat];
          }
        });

        const nav = new mapboxgl.NavigationControl({
          visualizePitch: true
          });
        
        this.map.addControl(nav, 'top-left');
      },

      preloadAllImages() {
        this.listing.images.forEach((image, index) => {
          this.preloadImage(this.listing.images[index]);
        });
      },

      preloadImage(url) {
        const img = new Image();
        img.src = url;
      },

      initSwipe() {
        const imageContainer = this.$refs.imageContainer;
        if (imageContainer instanceof HTMLElement) {
            const mc = new Hammer(imageContainer);
            mc.on("swipeleft", () => this.nextImage());
            mc.on("swiperight", () => this.previousImage());
        } else {
            console.error("Image container not found or is not a DOM element");
        }
      },

      goBack() {
        window.history.back();
      },

      handleScroll() {
        if (this.isMapVisible(this.$refs.mapContainer)) {
          this.flyToCoordinate();
        }
      },

      isMapVisible(el) {
        if (!el) return false;
        const rect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      },

      async toggleFavorite() {
        // Toggle the favorite state immediately
        this.isFavorite = !this.isFavorite;
        
        try {
          if (this.isFavorite) {
            await this.favouriteStore.addFavourite(this.listing.listing_id);
          } else {
            await this.favouriteStore.removeFavourite(this.listing.listing_id);
          }
        } catch (error) {
          // Revert the UI state if the API call fails
          this.isFavorite = !this.isFavorite;
          console.error('Error toggling favorite:', error);
        }
      }
    }
    
};

</script>

<style>

  .dt-property-detail {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    height: 100vh;
    flex: 1;
  }

  .dt-heading {
    width: 80%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }

  .dt-summary {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .dt-price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }

  .price {
    color: #B19977;
    font-size: 40px;
    text-align: left;
  }

  .pricesqm {
    color: #A0A0A0;
    font-size: 16px;
    text-align: left;
  }

  .overview-wrapper {
    display: flex;
    flex-direction: column;
    left: 0;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .overview {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .overview-section {
    color: #A0A0A0;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  .overview-section img {
    height: 16px;
    margin-right: 10px;
  }

  .dt-location {
    font-size: 16px;
    color: #323232;
    cursor: pointer;
  }

  .dt-rooms img {
    height: 16px;
    margin-right: 10px;
  }

  .dt-sizing img {
    height: 16px;
    margin-right: 10px;
  }

  .dt-location-container img {
    height: 16px;
    margin-right: 10px;
  }

  .dt-divider {
    margin: 10px; 
  }

  .dt-get-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    background-color: #B19977;
    color: white;
    cursor: pointer;
    font-size: 18px;
    border-radius: 20px;
  }

  .dt-get-contact:hover {
    background-color: #997C54;
  }

  .dt-get-contact img {
    width: 18px;
    height: auto;
    padding-right: 10px;
  }

  .dt-get-contact-link:link {
    text-decoration: none;
  }

  .dt-get-contact-link:visited {
    text-decoration: none;
  }

  .dt-get-contact-link:hover {
    text-decoration: none;
  }

  .dt-get-contact-mobile {
      display: none;
  }

  .dt-get-contact-mobile-link:link {
    text-decoration: none;
  }

  .dt-get-contact-mobile-link:visited {
    text-decoration: none;
  }

  .dt-get-contact-mobile-link:hover {
    text-decoration: none;
  }

  .dt-spec-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 70%;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 100px;
  }

  .dt-spec-title {
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    width: 100%;
  }

  .dt-features {
    display: grid;
    width: 100%;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #A0A0A0;
  }

  .dt-feature-item {
    display: flex;
    gap: 10px;
    width: auto;
    height: 16px;
    color: black;
  }

  .dt-feature-item img {
    width: 16px;
    height: 16px;
  }

  .dt-feature-item.disabled{
    filter: invert(80%);
  }

  .dt-description {
    color: #A0A0A0;
    font-size: 16px;
    text-align: left;
  }

  .all-images-container {
    width: 80%;
    position: relative;
  }

  .dt-image-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    overflow: hidden;
    border-radius: 20px;
  }

  .dt-image-main {
    width: 70%;
    height: 500px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .dt-image-main img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    cursor: pointer;
  }

  .dt-image-main img[lazy="loading"] {
    width: 50px;
    height: auto;
    margin: auto;
  }

  .dt-image-thumb {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 500px;
    overflow: hidden;
  }

  .dt-image-thumb1 {
    cursor: pointer;
    position: relative;
  }

  .dt-image-thumb2 {
    cursor: pointer;
    position: relative;
  }

  .dt-image-thumb img {
    width: 100%;
    height: 100%;
    height: 250px;
    object-fit: cover;
    display: block;
    cursor: pointer;
  }

  .dt-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
    pointer-events: none;
  }

  .dt-image-main:hover .dt-overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .dt-image-thumb1:hover .dt-overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .dt-image-thumb2:hover .dt-overlay {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .dt-image-navigation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-items: center;
    position: absolute;
    top: 0;
  }

  .dt-image-navigation-container button {
    width: 30px;
    height: 30px;
    background-color: rgba(235, 233, 233, 0.8);
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin: 0px 15px 0px 15px;
    justify-content: center;
  }

  .dt-image-navigation-container button img{
    height: 16px;
    margin: auto;
  }

  .dt-map-container {
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 100%;
    background-color: white;
}

  #dt-map {
    flex-grow: 1;
    z-index: 1;
    }

  .dt-image-navigation-container button:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .overview-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    flex-direction: row;
    width: 100%;
    gap: 30px;
    margin-bottom: 30px;
  }

  .overview-value {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    grid-gap: 10px;
  }

  .overview-label {
    color: #A0A0A0;
    font-size: 14px;
  }


  .overview-value img {
    height: 40px;
    grid-column: 1;
    grid-row: span 2; 
  }

  .grid-item:nth-child(1) {
    grid-column: 1;
    grid-row: span 2;
  }

  .backToSearch {
    display: none;
  }

  .favourite-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80%;
    cursor: pointer;
    padding-right: 1rem;
    position: absolute;
    top: 25px;
    right: 10px;
  }

  .favourite-icon svg {
    height: 30px;
  }

  @media (max-width: 768px) {

    .dt-property-detail {
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .dt-heading {
      justify-content: center;
      flex-direction: column;
      width: 85%;
      padding-top: 0px;
    }

    .price {
      font-size: 24px;
    }

    .dt-dropdown-wrapper {
      gap: 3px;
    }

    .dt-dropdown-toggle {
      height: 35px;
    }

    .overview {
      gap: 4px;
    }

    .overview-section {
      font-size: 12px;
    }

    .overview-section img{
      height: 12px;
    }

    .dt-get-contact {
      display: none;
    }

    .dt-get-contact-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 70px;
      background-color: #B19977;
      color: white;
      cursor: pointer;
      font-size: 18px;
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      gap: 10px;
      z-index: 2;
    }

    .dt-get-contact-mobile img {
      width: 16px;
      height: auto;
    }

    .dt-image-container {
      flex-direction: column;
      justify-content: center;
    }

    .dt-image-main {
      width: 100%;
    }

    .dt-image-thumb {
      display: none;
    }

    .dt-spec-container {
      width: 85%;
    }

    .dt-features {
      grid-template-columns: auto auto auto;
      gap: 15px;
      margin-bottom: 20px;
      font-size: 12px;
    }

    .dt-image-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      overflow: hidden;
    }

    .dt-image-main {
      height: 250px;
    }

    .dt-image-main img {
      width: 100%;
      height: 100%;
    }

    .dt-image-container {
      width: 100%;
      border-radius: 0px;
    }

    .dt-price-container {
      justify-content: flex-start;
      gap: 10px;
    }

    .backToSearch {
      color: #3A6083;
      font-size: 12px;
      margin-bottom: 10px;
      cursor: pointer;
      display: block;
    }

}

</style>
