<template>
  <div id="filter-header-template">
    <div class="filter-header-container">
    <div class="filter-header">
      <div class="filter-header-left">
        <button class="dropdown-filter" @click="toggleFilterSidebar">
          <img src="../assets/filter.png">
          <span>Filters</span>
          <span id="number-of-filters">{{ numberOfFilters }}</span>
        </button>
        <button class="scroll-button" @click="scrollLeft" v-if="isOverflow">
          <img src="../assets/previous_tags.svg" alt="Scroll Left">
        </button>
        <div class="filter-tags-container" ref="tagsContainer">
          <div class="filter-tags">
            <div class="tags">
              <span v-for="(value, key) in filters" :key="key" class="tag" @click="removeFilter(key)">
                <span class="tag-text">
                  {{ formatKey(key) }} {{ formatValue(value, key) }}
                </span>
                <img src="../assets/remove_tag.svg" class="remove-tag"/>
              </span>
              <span class="save-filter" v-if="Object.keys(filters).length > 0 && !isOverflow" @click="toggleSaveFilter">+ Save filters</span>
            </div>
          </div>
      </div>
      <button class="scroll-button" @click="scrollRight" v-if="isOverflow">
        <img src="../assets/next_tags.svg" alt="Scroll Right">
      </button>
      <span class="save-filter" v-if="Object.keys(filters).length > 0 && isOverflow" @click="toggleSaveFilter">+ Save filters</span>
    </div>
      <div class="filter-header-right">
        <div class="fh-dropdown-container">
            <button class="dropdown-filter" ref="sortDropdownButton" @click="toggleSortDropdown">
              <span>{{ formatSorting() }}</span>
              <img src="../assets/dropdown.png">
              <div class="dropdown-menu" v-show="isSortDropdownOpen">
              <a class="dropdown-item" href="#" @click="sort('lowest_price')">Lowest price</a>
              <a class="dropdown-item" href="#" @click="sort('highest_price')">Highest price</a>
              <a class="dropdown-item" href="#" @click="sort('lowest_price_sqm')">Lowest price per sqm</a>
              <a class="dropdown-item" href="#" @click="sort('highest_price_sqm')">Highest price per sqm</a>
              <a class="dropdown-item" href="#" @click="sort('newest')">Newest Built</a>
              <a class="dropdown-item" href="#" @click="sort('oldest')">Oldest Built</a>
            </div>
            </button>
        </div>
        <div class="fh-dropdown-container">
            <button class="dropdown-filter" ref="currencyDropdownButton" @click="toggleCurrencyDropdown">
              <span>{{ selectedCurrency }}</span>
                <img src="../assets/dropdown.png">
                <div class="dropdown-menu" v-show="isCurrencyDropdownOpen">
                  <a class="dropdown-item" href="#" @click="selectCurrency('IDR')">IDR</a>
                  <a class="dropdown-item" href="#" @click="selectCurrency('EUR')">EUR</a>
                  <a class="dropdown-item" href="#" @click="selectCurrency('USD')">USD</a>
                  <a class="dropdown-item" href="#" @click="selectCurrency('AUD')">AUD</a>
                  <a class="dropdown-item" href="#" @click="selectCurrency('SGD')">SGD</a>
                  <a class="dropdown-item" href="#" @click="selectCurrency('GBP')">GBP</a>
                  <a class="dropdown-item" href="#" @click="selectCurrency('BTC')">BTC</a>
              </div>
            </button>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="fh-overlay" v-if="showFilterSidebar" @click="toggleFilterSidebar"></div>
  <transition name="slide">
      <filter-sidebar v-model:isVisible="showFilterSidebar" />
  </transition>

    <div class="fh-overlay center-modal" v-if="showSaveFilter && Object.keys(filters).length > 0" @click.self="toggleSaveFilter">
      <div class="save-filter-container">
        <div class="save-filter-header">
          <span>Save filters</span>
        </div>
        <div class="filter-tags">
            <div class="tags">
              <span v-for="(value, key) in filters" :key="key" class="tag" @click="removeFilter(key)">
                <span class="tag-text">
                  {{ formatKey(key) }} {{ formatValue(value, key) }}
                </span>
                <img src="../assets/remove_tag.svg" class="remove-tag"/>
              </span>
            </div>
        </div>
        <input type="text" v-model="filterName" placeholder="Filter name" />
        <div class="save-filter-button" 
             @click="filterName.trim() && saveFilter(filters, filterName)"
             :class="{ 'disabled': !filterName.trim() }">
          Save filters
        </div>
      </div>
    </div>
</template>

<script>

import FilterSidebar from './FilterSidebar.vue';
import { mapState } from 'vuex';
import { useSavedSearchStore } from '@/stores/savedSearches';

export default {

  components: {
    'filter-sidebar': FilterSidebar
  },

  data() {
    return {
      showFilterSidebar: false,
      showSaveFilter: false,
      isCurrencyDropdownOpen: false,
      isSortDropdownOpen: false,
      defaultCurrency: 'EUR',
      scrollPosition: 0,
      sorting: '',
      isOverflow: false,
      savedSearchStore: useSavedSearchStore(),
      filterName: '',
    };
  },

  watch: {
    '$route'(to, from) {
      if (to.query !== from.query) {
        this.checkOverflow();
      }
    },

    '$route.query': {
      immediate: true,
      handler(query) {
        if (query.sort) {
          this.sort(query.sort);
        }
      }
    }
      
  },

  computed: {

    selectedCurrency() {

      // Get selected currency from the url
      const cur = this.$route.query.currency || this.defaultCurrency;

      return cur.toUpperCase()
      },

    ...mapState({
        rawFilters: state => state.filters,
      }),

      filters() {
        const query = this.$route.query;
        const nonEmptyFilters = {};

        for (const key in query) {
          if (key !== 'currency' && key !== 'sort' && query[key] !== '' && query[key] !== 'Any') {
            nonEmptyFilters[key] = query[key];
          }
        }

        return nonEmptyFilters;
      },

      numberOfFilters() {
        return Object.values(this.filters).filter(value => value !== '').length;
      },

      isOverflowing() {
        return false;
      },
  },

  methods: {

    toggleSaveFilter() {
      this.showSaveFilter = !this.showSaveFilter;
    },

    saveFilter(filters, filterName) {

      this.savedSearchStore.addSavedSearch(filters, filterName);
      this.toggleSaveFilter();
    },


    toggleFilterSidebar() {
      this.showFilterSidebar = !this.showFilterSidebar;
    },

    toggleCurrencyDropdown() {
      this.isCurrencyDropdownOpen = !this.isCurrencyDropdownOpen;
    },

    toggleSortDropdown() {
      this.isSortDropdownOpen = !this.isSortDropdownOpen;
    },

    selectCurrency(currency) {

      // Add currency param to the url
      this.$router.push({ 
        path: '/search',
        query: { 
          ...this.$route.query,
          currency: currency
        } 
      });

      // Store currency to vue store
      this.$store.dispatch('applyFilter', { filterName: 'currency', value: currency });
    },

    sort(sorting) {
      this.sorting = sorting;

      // Add currency param to the url
      this.$router.push({ 
        path: '/search',
        query: { 
          ...this.$route.query,
          sort: sorting
        } 
      });

      // Store sort to vue store
      this.$store.dispatch('applyFilter', { filterName: 'sort', value: sorting });
    },

    formatSorting() {
      if (this.sorting == "lowest_price") {
        return 'Lowest price'
      } else if (this.sorting == "highest_price") {
        return 'Highest price'
      } else if (this.sorting == "lowest_price_sqm") {
        return 'Lowest price per sqm'
      } else if (this.sorting == "highest_price_sqm") {
        return 'Highest price per sqm'
      } else if (this.sorting == "newest") {
        return 'Newest built'
      } else if (this.sorting == "oldest") {
        return 'Oldest built'
      } else {
        return 'Sort By'
      }
    },

    handleClickOutside(event) {
      if (!this.$refs.sortDropdownButton.contains(event.target)) {
        this.isSortDropdownOpen = false;
      }

      if (!this.$refs.currencyDropdownButton.contains(event.target)) {
        this.isCurrencyDropdownOpen = false;
      }
    },

    formatKey(key) {
      if (key === 'minPrice') {
        return 'Min: ';
      } else if (key === 'maxPrice') {
        return 'Max: ';
      } else if (key === 'region') {
        return '';
      } else if (key === 'buildingSize') {
        return 'Building: ';
      } else if (key === 'landSize') {
        return 'Land: ';
      } else if (key === 'bedroom') {
        return 'Bedroom: ';
      } else if (key === 'bathroom') {
        return 'Bathroom: ';
      } else if (key === 'type') {
        return '';
      } else {
        return key;
      }
    },

    formatValue(value, key) {
      if (key === 'minPrice' || key === 'maxPrice' || key === null) {
        if (value === '' || value === undefined) {
          return '';
        }

        const number = parseFloat(value);
        if (isNaN(number)) {
          return value;
        }

        return number.toLocaleString();
      }

      else if (key === 'landSize' || key === 'buildingSize') {
        if (value === '1') {
          return '0-300 sqm'
        } else if (value === '2') {
          return '300+ sqm'
        }
      }

      else if (key === 'bedroom' || key === 'bathroom') {
        if (value === '1') {
          return '0-3'
        } else if (value === '2') {
          return '3+'
        } else if (value === 'Any') {
          return 'Any'
        }

      } 
      
      else if (key === 'type') {
        if (value === 'leasehold') {
          return 'Lease Hold'
        } else if (value === 'freehold') {
          return 'Free Hold'
        }
      } 
      
      else return value
    },

    removeFilter(key) {
      let updatedQuery = { ...this.$route.query };      
      delete updatedQuery[key];
      this.$router.push({ path: '/search', query: updatedQuery });
      this.$store.dispatch('applyFilter', { filterName: key, value: '' });
      this.$store.dispatch('fetchData');
    },

    scrollLeft() {
      const container = this.$refs.tagsContainer;
      container.scrollLeft -= 100; 
    },

    scrollRight() {
      const container = this.$refs.tagsContainer;
      container.scrollLeft += 100;
    },

    checkOverflow() {
      this.$nextTick(() => {
        const container = this.$refs.tagsContainer;
        if (container) {
          this.isOverflow = container.scrollWidth > container.clientWidth;
        }
      });
    }
  },

  mounted() {
    this.checkOverflow();
    this.sort('highest_price_sqm');
    window.addEventListener('resize', this.checkOverflow);
    document.addEventListener('click', this.handleClickOutside);
    
  },
  
  beforeUnmount() {
    window.removeEventListener('resize', this.checkOverflow);
    document.removeEventListener('click', this.handleClickOutside);
    
  },
}
</script>

<style>

#filter-header-template {
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  display: flex;
  background-color: white;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 160px;
  z-index: 2;
  padding-bottom: 5px;
}

.filter-header-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.filter-header {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: 50px;
  font-size: 16px;
  font-family: 'Sofia Sans', sans-serif;
  padding-top: 10px;
}

.filter-header-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#number-of-filters {
  background-color: #B19977; 
  color: white;
  border-radius: 50%;
  width: 23px;
  height: 23px; 
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-tags-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  height: 90%;
  max-width: 500px;
  overflow: scroll;
}

.filter-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.tags {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.tag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #F5F5F5;
  gap: 10px;
  padding: 0px 10px 0px 10px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
}

.tag-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  font-size: 12px;
}

.remove-tag {
  height: 10px;
}

.fh-dropdown-toggle {
  background-color: #ffffff;
  border: 1px solid #A0A0A0;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.fh-dropdown-toggle img {
  height: 20px;
  width: auto;
}

.fh-dropdown-toggle:hover {
  background-color: #F3F3F3;
}

.filter-header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.fh-dropdown-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100%;
}

.fh-dropdown-container span {
  margin: 6px;
}

.fh-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.scroll-button {
  cursor: pointer;
  border: none;
  background-color: white;
  padding: 10px 10px 3px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.slide-enter-from {
  transform: translateX(-100%);
}

.slide-enter-to {
  transform: translateX(0);
}

.slide-enter-active {
  transition: transform 0.3s ease;
}

.slide-leave-from {
  transform: translateX(0);
}

.slide-leave-to {
  transform: translateX(-100%);
}

.slide-leave-active {
  transition: transform 0.3s ease;
}

.save-filter {
  padding: 9px;
  background-color: #3A6083;
  color: #FFFFFF;
  border-radius: 20px;
  cursor: pointer;
  width: max-content;
  height: max-content;
}

.center-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-filter-container {
  width: 30%;
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem
}

.save-filter-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.save-filter-container input {
  width: 50%;
  padding: 10px;
  border: 1px solid #A0A0A0;
  border-radius: 10px;
  
}

.save-filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B19977;
  color: #FFFFFF;
  border-radius: 15px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}

.save-filter-button.disabled{
  background-color: #A0A0A0;
  cursor: not-allowed;
}

@media (max-width: 768px) {

  .filter-header {
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 0px;
    gap: 10px;
    font-size: 12px;
  }

  #filter-header-template {
    top: 109px;
  }

  .filter-header-right {
    gap: 10px;
  }

  .scroll-button {
    display: none;
  }

  #number-of-filters {
    font-size: 10px;
  }

  .filter-header-container {
    align-items: center;
  }

  .filter-tags-container {
    display: none;
  }

  .slide-enter-from {
    transform: translateY(100%);
  }

  .slide-enter-to {
    transform: translateY(0);
  }

  .slide-enter-active {
    transition: transform 0.3s ease;
  }

  .slide-leave-from {
    transform: translateY(0);
  }

  .slide-leave-to {
    transform: translateY(100%);
  }

  .slide-leave-active {
    transition: transform 0.3s ease;
  }
}

</style>
