<template>
    <div class="contact-us">
        <div class="list-with-us">
            <div class="list-title">Showcase Your Property with Estad</div>
            <div class="list-subtitle">Join Bali's premier real estate platform. Connect with buyers and expand your reach. List with us today!</div>
            <div class="listing-intro-wrapper">
                <div class="listing-intro">Discover the Perks of Listing with
                    <span>Estad</span></div>
                <div class="benefits">
                    <div class="benefit">
                        <img src="../assets/benefit2.svg"/>
                        <div class="benefit-title">Maximize Visibility</div>
                        <div class="benefit-text">Enhance your property’s presence in Bali’s bustling real estate market with targeted exposure on Estad.</div>
                    </div>
                    <div class="benefit">
                        <img src="../assets/benefit2.svg"/>
                        <div class="benefit-title">Generate More Leads</div>
                        <div class="benefit-text">Connect directly with interested buyers and accelerate your sales process through our specialized platform</div>
                    </div>
                    <div class="benefit">
                        <img src="../assets/benefit3.svg"/>
                        <div class="benefit-title">Get First Dibs on Innovative Features</div>
                        <div class="benefit-text">Be the first to access cutting-edge tools that transform how you manage and market your real estate listings on Estad</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form">
            <div class="form-title">What We Need From You</div>
            <div class="form-grid">
                <input class="field" id="email" v-model="email" type="email" placeholder="Enter email address" required/>
                <input class="field" id="website" v-model="website" type="url" placeholder="Enter company website (optional)" />
                <textarea class="textarea" id="message" v-model="message" type="text" placeholder="Enter a message"></textarea>
                <div id="agreement">
                    <input id="checkbox" type="checkbox" v-model="agreement" />
                    <div>By sharing your data, you agree to our terms of use and privacy policy, ensuring your information is handled with the utmost care and confidentiality.</div>
                </div>
                <button @click="sendEmail" id="submit" :disabled="!isEmailValid || !agreement || !message">Submit request</button>
                <div class="response-success" v-if="isEmailSent===true">Your request has been sent! We will get back to you as soon as possible.</div>
                <div class="response-failed" v-if="isEmailSent===false" @click="otherInquiries">Request is not sent. Contact us here.</div>
            </div>
        </div>
        <div class="about">
            <div class="about-image">
                <img src="../assets/estad-big-logo.svg"/>
            </div>
            <div class="about-title">About 
                <span>Estad</span></div>
            <div class="about-text">
                <span>Estad </span>
                has an ambitious mission to build overlay services on top of real estate data. The 
                <span>Estad</span> aggregator presents the first step of this roadmap.<br><br>
                Early partners will receive preferential treatment for additional opt-in services.<br><br>
                The 
                <span>Estad</span> team consists of professionals with work experience at supranational financial institutions, market leading web 3 companies, as well as deep experience of working and leading in web2 and web3 startups.</div>
        </div>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactUs',
  data() {
    return {
      email: '',
      website: '',
      message: '',
      agreement: false,
      isEmailSent: '',
    }
  },

  methods: {
    sendEmail() {
        if (this.isEmailValid && this.agreement) {
            const templateParams = {
                email: this.email,
                website: this.website,
                message: this.message,
                agreement: this.agreement,
            };
            emailjs.send('service_wxxgoli', 'template_elosqsm', templateParams, 'AbdxcJm4_l5TmC0I7')
                .then(() => {
                    this.isEmailSent = true;
                    this.email = '';
                    this.website = '';
                    this.message = '';
                    this.agreement = false;
                }, () => {
                    this.isEmailSent = false;
                    this.email = '';
                    this.website = '';
                    this.message = '';
                    this.agreement = false;
                });
        } else {
            this.isEmailSent = false;
            this.email = '';
            this.website = '';
            this.message = '';
            this.agreement = false;
        }
    },

    otherInquiries() {
      const email = 'arletafenty@gmail.com';
      const subject = 'I would like to reach out';
      const body = '';
      window.open(`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
    }
  },

  computed: {
        isEmailValid() {
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            return regex.test(this.email);
        }
    }
}

</script>

<style>

.contact-us {
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

.list-with-us {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: auto;
}

.list-title {
    font-size: 50px;
    font-weight: 800;
    color: #323232;
    text-align: center;
}

.list-subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #A0A0A0;
    text-align: center;
}

.listing-intro-wrapper {
    width: 70%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    align-items: center;
}

.listing-intro {
    color: #323232;
    font-size: 40px;
    margin-bottom: auto;
    display: inline;
}

.listing-intro span{
    color: #B19977;
    font-weight: 800;
}

.benefits {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.benefit {
    display: grid;
    grid-template-columns: 20% auto;
    grid-template-rows: auto auto;
    grid-gap: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 20px;
}

.benefit img{
    height: 50px;
    width: 50px;
    grid-column: 1;
    grid-row: span 2;
    margin: auto;
}

.benefit-title {
    color: #323232;
    font-size: 18px;
}

.benefit-text {
    color: #A0A0A0;
    font-size: 16px;
}

.form {
    width: 100%;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
    margin: auto;
}

.form-title {
    color: #323232;
    font-size: 40px;
    text-align: center;
}

.form-grid {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto auto auto auto;
    grid-gap: 20px;
    width: 60%;
}

.field {
    height: 15px;
    padding: 15px;
    font-family: 'Sofia Sans', sans-serif;
    font-size: 16px;
    border-radius: 20px;
    border: 1px solid #A0A0A0;
}

.textarea {
    height: 80px;
    padding: 15px;
    font-family: 'Sofia Sans', sans-serif;
    font-size: 16px;
    border-radius: 20px;
    border: 1px solid #A0A0A0;
    padding-bottom: auto;
}

#email {
    grid-column: 1;
    grid-row: 1;
}

#website {
    grid-column: 2;
    grid-row: 1;
}

#message {
    grid-column: span 2;
    grid-row: 2;
}

#agreement {
    display: flex;
    flex-direction: row;
    grid-column: span 2;
    grid-row: 3;
    gap: 10px;
}

#checkbox {
    margin-bottom: auto;
}

#submit {
    grid-column: span 2;
    grid-row: 4;
    background-color: #B19977;
    color: white;
    cursor: pointer;
    font-size: 18px;
    border: 0;
    height: 40px;
    font-family: 'Sofia Sans', sans-serif;
    border-radius: 20px;
    width: 200px;
    margin: auto;
}

#submit:disabled {
    background-color: #b2b0af;
    cursor: no-drop;
}

.response-success {
    grid-column: span 2;
    grid-row: 5;
    color: #B19977;
}

.response-failed {
    grid-column: span 2;
    grid-row: 5;
    color: #892005;
    cursor: pointer;
}

.about {
    display: grid;
    grid-template-columns: auto 60%;
    grid-template-rows: auto auto;
    width: 70%;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.about-image {
    grid-column: 1;
    grid-row: span 2;
    margin: auto;
}

.about-image img {
    height: 200px;
    width: auto;
}

.about-title {
    grid-column: 2;
    grid-row: 1;
    color: #323232;
    font-size: 40px;
    display: inline;
    text-align: center;
}

.about-title span{
    color: #B19977;
}

.about-text {
    grid-column: 2;
    grid-row: 2;
    color: #A0A0A0;
    font-size: 16px;
}

.about-text span{
    color: #B19977;
}

@media (max-width: 768px) {

    .list-title {
        font-size: 30px;
    }

    .listing-intro-wrapper {
        flex-direction: column;
        width: 90%;
    }

    .listing-intro {
        font-size: 18px;
        text-align: center;
    }

    .benefit img{
        height: 45px;
        width: 45px;
    }

    .about {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
    }

    .about-title {
        grid-column: span 2;
        grid-row: 1;
    }

    .about-text {
        grid-column: span 2;
        grid-row: 2;
    }

    .about-image {
        display: none;
    }

    .form-title {
        font-size: 24px;
    }

    #email {
        grid-column: span 2;
        grid-row: 1;
    }

    #website {
        grid-column: span 2;
        grid-row: 2;
    }

    #message {
        grid-column: span 2;
        grid-row: 3;
    }

    #agreement {
        grid-column: span 2;
        grid-row: 4;
    }

    #submit {
        grid-column: span 2;
        grid-row: 5;
    }

    .response-success {
        grid-column: span 2;
        grid-row: 6;
    }

    .response-failed {
        grid-column: span 2;
        grid-row: 6;
    }


}
</style>