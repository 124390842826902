import { defineStore } from 'pinia'
import axios from 'axios'

export const useGeojson = defineStore('geoJsonStore', {
  state: () => ({
    features: [],
    selectedFeature: {},
    error: null,
    isLoading: true
  }),

  actions: {
    setFeatures(features) {
      this.features = features;
    },

    setSelectedFeature(feature) {
      this.selectedFeature = feature;
    },

    async fetchGeojson() {
      const apiUrl = 'https://us-central1-realsqm-8ca89.cloudfunctions.net/api';
      const url = `${apiUrl}/geojson`;
    
      try {
        const response = await axios.get(url);
        this.setFeatures(response.data.features);
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
  }
})