<template>
  <MapboxContainer v-if="!isMobile" />
  <MapStory v-if="!isMobile" />
  <MapMenu v-if="!isMobile" />
  <HomePageMobile v-if="isMobile" />
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import MapboxContainer from '@/components/MapboxContainer.vue'
import MapStory from '@/components/MapStory.vue'
import MapMenu from '@/components/MapMenu.vue'
import HomePageMobile from '@/components/HomePageMobile.vue'
import { useGeojson } from '@/stores/geojson'

const isMobile = ref(false)

const checkIfMobile = () => {
  isMobile.value = window.innerWidth <= 768
}

onMounted(() => {
  checkIfMobile()
  window.addEventListener('resize', checkIfMobile)
  useGeojson().fetchGeojson()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkIfMobile)
})
</script>

<style>
/* Add any global styles if needed */
</style>
