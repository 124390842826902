<template>
    <div class="wrapper">
        <div class="my-account-container">
            <MyAccountMenu />
            <div class="content">
                <Favourites v-if="menuStore.activeTab === 'favourites'"/>
                <SavedSearch v-if="menuStore.activeTab === 'saved-searches'"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useMenuStore } from '@/stores/menu';
import { useRouter, useRoute } from 'vue-router';
import { watch, onMounted } from 'vue';
import MyAccountMenu from '@/components/MyAccountMenu.vue';
import Favourites from '@/components/FavouritesComponent.vue';
import SavedSearch from '@/components/SavedSearch.vue';

const menuStore = useMenuStore();
const router = useRouter();
const route = useRoute();

// Watch for menuStore.activeTab changes and update the route
watch(() => menuStore.activeTab, (newTab) => {
  if (newTab === 'favourites') {
    router.push('/account/favourites');
  } else if (newTab === 'saved-searches') {
    router.push('/account/saved-searches');
  }
});

// Set initial tab based on route
onMounted(() => {
  if (route.path.includes('favourites')) {
    menuStore.activeTab = 'favourites';
  } else if (route.path.includes('saved-searches')) {
    menuStore.activeTab = 'saved-searches';
  }
});
</script>

<style>

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem 4rem 2rem;
}

.my-account-container {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 1fr;
    width: 100%;
}

.content {
    grid-column: 2;
    grid-row: 1;
    padding: 1rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .my-account-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}
</style>