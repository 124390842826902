<template>
  <div class="search-page">
    <SearchHeading />
    <FilterHeader :query="$route.query" />
    <PropertyList :query="$route.query"/>
  </div>
</template>

<script>
import SearchHeading from '@/components/SearchHeading.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import PropertyList from '@/components/PropertyList.vue';
//import PropertyCategory from '@/components/PropertyCategory.vue';

export default {
  name: 'SearchProperty',

  components: {
    SearchHeading,
    FilterHeader,
    //PropertyCategory,
    PropertyList
  },

  mounted() {
    this.$nextTick(() => {
      const listElement = document.querySelector('.real-estate-list');
      if (listElement) {
        window.scrollTo({ 
          top: listElement.offsetTop, 
          behavior: 'smooth' 
        });
      }
    });
  }
};
</script>

<style>
</style>
