import { defineStore } from 'pinia'
import { useUserStore } from './user'
import axios from "axios";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../firebase';

export const useSavedSearchStore = defineStore('savedSearch', {
  state: () => ({
    savedSearches: [],
    error: null,
    isLoading: true
  }),
  actions: {
    setSavedSearch(savedSearches) {
      this.savedSearches = savedSearches;
    },

    async fetchFirebaseUser() {
      const userStore = useUserStore();
      
      // Wait for auth state to be determined
      return new Promise((resolve) => {
        const auth = getAuth(app);
        onAuthStateChanged(auth, (user) => {
          userStore.setUser(user);
          resolve(user);
        });
      });
    },

    async fetchSavedSearches() {
      const user = await this.fetchFirebaseUser();
      
      if (!user) {
        this.isLoading = false;
        return;
      }

      const token = await user.getIdToken();

      try {
        const response = await axios.get("https://us-central1-realsqm-8ca89.cloudfunctions.net/api/searches", {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        });

        this.setSavedSearch(response.data.savedSearches);
      } catch (error) {
        this.error = error.response?.data?.error || error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async addSavedSearch(search, name) {
      const user = await this.fetchFirebaseUser();
      
      if (!user) {
        return;
      }

      const token = await user.getIdToken();

      try {
        const response = await axios.post(
          "https://us-central1-realsqm-8ca89.cloudfunctions.net/api/searches",
          { search, name },
          {
            headers: {
              Authorization: `Bearer ${token}`, 
            },
          }
        );

        if (response.status === 200) {
          this.savedSearches.push({ search, name });
        }

      } catch (error) {
        this.error = error.response.data.error;
      }
    },

    async removeSavedSearch(searchItem) {
      const user = await this.fetchFirebaseUser();

      if (!user) {
        return;
      }

      const token = await user.getIdToken();

      try {
        const response = await axios.delete(
          "https://us-central1-realsqm-8ca89.cloudfunctions.net/api/searches",
          {
            headers: {
              Authorization: `Bearer ${token}`, 
            },
            data: searchItem
          }
        );

        if (response.status === 200) {
          this.savedSearches = this.savedSearches.filter(item => 
            item.search !== searchItem.search || item.name !== searchItem.name
          );
        }

      } catch (error) {
        this.error = error.response.data.error;
      }
    }
  }
})