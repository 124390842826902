<template>
  <header id="header">
    <div class="header-container">
      <img src="@/assets/estad.svg" alt="realsqm" @click="navigateHome" style="cursor:pointer;">
      <div class="sh-dropdown-container">
        <div class="sh-burger-menu" ref="menu" @click="toggleMenu">
          <img src="@/assets/menu_close.svg" alt="menuClose">
        </div>
      </div>
    </div>
  </header>
  <div class="sh-overlay" v-if="menuStore.isMenuOpen" @click="toggleMenu"></div>
  <transition name="slide-left" v-if="menuStore.isMenuOpen">
          <MenuSidebar />
        </transition>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useMenuStore } from '@/stores/menu'
import MenuSidebar from './MenuSidebar.vue'

const router = useRouter()
const menuStore = useMenuStore()

const toggleMenu = () => {
  menuStore.toggleMenu()
}

const navigateHome = () => {
  router.push('/')
}
</script>

<style>

#header {
  display: flex;
  justify-content: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 60px;
  background-color: white;
  z-index: 1;
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 30px 8px 30px;
}

header img {
  width: 100px;
}

.menu-line {
  width: 100%;
  display: flex;
  padding-bottom: 40px;
  flex-direction: row;
  justify-content: right;
}

.sh-burger-menu {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  right: 0;
}

.sh-burger-menu img {
  width: 30px;
  height: auto;
}

.sh-dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.sh-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

@media (max-width: 768px) {
  header img {
    width: 70px;
  }
}
</style>
