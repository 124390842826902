<template>
    <div class="tab-title">
        <h1>My Favourites</h1>
    </div>
    <div class="favourites-container">
        <PropertyList :loadOnScroll="true" :favouritesPage="true" :itemsPerPage="6"/>
    </div>
</template>

<script setup>
import { useFavouriteStore } from '@/stores/favourite';
import PropertyList from './PropertyList.vue';
const favouriteStore = useFavouriteStore();

favouriteStore.fetchFavourites();
</script>

<style>

.favourites-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
}
</style>