import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './assets/main.css'
import router from './router'
import store from './store'
import VueLazyload from 'vue-lazyload'
//import { auth, signInAnonymously } from './firebase';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGoogleOauth from 'vue3-google-oauth2';
import './styles/global.css';
import './styles/variables.css';
import { useUserStore } from './stores/user'


const app = createApp(App)
const pinia = createPinia()

const gauthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'consent'
};

app.use(VueGoogleOauth, gauthOptions);
app.use(router)
app.use(store)
app.use(Vue2TouchEvents);
app.use(pinia)

app.use(VueLazyload, {
    preLoad: 1.3,
    error: require('@/assets/realsqm.png'),
    loading: require('@/assets/loading.gif'),
    attempt: 1
  });


/*signInAnonymously(auth)
.then(() => {
  console.log('User signed in anonymously');
})
.catch((error) => {
  console.error('Error signing in anonymously:', error);
});*/

const userStore = useUserStore()
userStore.init()

app.mount('#app');