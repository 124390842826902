import { defineStore } from 'pinia'
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { app } from '@/firebase'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    isLoading: false
  }),

  actions: {
    setUser(user) {
      this.user = user;
    },

    async loginWithGoogle() {
      try {
        this.isLoading = true;
        const provider = new GoogleAuthProvider();
        const auth = getAuth(app);

        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        this.setUser(user);
        console.log('user', user);

        if (user) {
          const token = await user.getIdToken();
          await fetch('https://us-central1-realsqm-8ca89.cloudfunctions.net/api/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
        }
      } catch (error) {
        alert("Login failed!");
      } finally {
        this.isLoading = false;
      }
    },

    async logout() {
      try {
        this.isLoading = true;
        const auth = getAuth(app);
        await auth.signOut();
        this.setUser(null);
      } catch (error) {
        alert("Logout failed!");
      } finally {
        this.isLoading = false;
      }
    },

    init() {
      const auth = getAuth(app);
      onAuthStateChanged(auth, (user) => {
        this.setUser(user);
      });
    }
  }
})

